

export const rolesObj = {
    HOD : "HOD",
    ADMIN : "ADMIN",
    DOCTOR : "DOCTOR",
    PATIENT : "PATIENT",
    SUBADMIN : "SUBADMIN",
}

export const businessTypeObj = {
    MANUFACTURER: "MANUFACTURER",
    IMPORTER: "IMPORTER",
    EXPORTER: "EXPORTER",
    WHOLESALER: "WHOLESALER",
    RESELLER: "RESELLER",
    OTHERS: "OTHERS",
}


export const businessNatureObj = {

    PROPRIETOR: "PROPRIETOR",
    PARTNERSHIP: "PARTNERSHIP",
    PRIVATELTDCO: "PRIVATE LTD CO.",
    PUBLICLTDCO: "PUBLIC LTD CO.",
    ONEPERSONCOMPANY: "ONE PERSON COMPANY",
    OTHERS: "OTHERS",
}


export const getAge = (dateString)  => {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}


export const getBmiFromHeightandWeigth = (height,weight) => {

     let heightinMeter = height / 100;
    let bmi = weight / (heightinMeter * heightinMeter);

    return bmi
}